<template>
  <div class="wrapper">
    <AppSidebar />
    <Suspense>
      <template #default>
        <div id="content-page" class="content-page">
          <AppMusicPlayer :id="id" />
        </div>
      </template>
      <template #fallback>
        <div>Loading ...</div>
      </template>
    </Suspense>
  </div>
  <!-- <AppFooter /> -->
</template>

<script>
import { Suspense } from "vue";
import AppSidebar from "@/components/layouts/AppSidebar.vue";
import AppMusicPlayer from "@/components/Pages/AppMusicPlayer.vue";
// import AppFooter from "@/components/layouts/AppFooter.vue";
export default {
  name: "MusicPlayer",
  props: {
    id: String,
  },
  components: {
    Suspense,
    AppSidebar,
    AppMusicPlayer,
    // AppFooter,
  },
};
</script>

<style>
</style>