<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="iq-card">
                <div class="iq-card-body">
                    <div class="row" v-for="play in getPlayer" :key="play.id">
                        <div class="col-lg-4">
                            <img :src="play.img" class="img-fluid w-100" alt="">
                        </div>
                        <div class="col-lg-8">
                            <div class="d-flex align-items-top justify-content-between iq-music-play-detail">
                                <div class="music-detail">
                                <h3>{{ play.desc_ar }}</h3>
                                <span>{{ play.title_ar }}</span>
                                <p class="mb-0">{{ play.year }}</p>
                                <!-- <p >Song   ·  45 Plays</p> -->
                                    <div class="d-flex align-items-center">                                       
                                        <a @click="getFeed(play)" class="btn btn-primary iq-play mr-2">تشغيل - {{ play.desc_ar }}</a>
                                    </div>
                                </div>
                                <!-- <div class="music-right">
                                    <div class="d-flex align-items-center">
                                        <div class="iq-circle mr-2 share"><a href="javascript:void();"><i class="las la-share-alt-square text-primary"></i></a></div>
                                        <div class="iq-circle mr-2"><a href="javascript:void();"><i class="ri-heart-fill  text-primary"></i></a></div>                 
                                        <div class="iq-circle">
                                            <a href="javascript:void();"><i class="las la-download text-primary"></i></a>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <div class="iq-card">
            <div class="iq-card-body">
                <ul class="list-unstyled iq-music-slide mb-0">
                    <li class="mb-3" v-for="play in getCat" :key="play.id">
                        <div class="d-flex justify-content-between align-items-center row" v-if="play.id != this.$route.params.id">
                           <div class="media align-items-center col-10 col-md-5">
                              <div class="iq-realese-song ">
                                 <a @click="getFeed(play)"><img :src="play.img" class="img-border-radius avatar-60 img-fluid" alt=""></a>
                              </div>
                              <div class="media-body ml-3">
                                 <p class="mb-0">{{ play.desc_ar }}</p>
                                 <small>{{ play.title_ar }}</small>
                              </div>
                           </div>
                           <p class="mb-0 col-md-2 col-md-2 iq-music-time">9:52</p>
                           <p class="mb-0 col-md-2 col-md-2 iq-musc-icone"><i class="lar la-star font-size-20"></i></p>
                           <p class="mb-0 col-2 col-md-2 iq-music-play" @click="getFeed(cat)"><i class="las la-play-circle font-size-32"></i></p>
                           <!-- <div class="iq-card-header-toolbar iq-music-drop d-flex align-items-center col-2 col-md-1">
                              <div class="dropdown">
                                 <span class="dropdown-toggle text-primary" id="dropdownMenuButton2" data-toggle="dropdown" aria-expanded="false" role="button">
                                    <i class="ri-more-2-fill text-primary"></i>
                                 </span>
                                 <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton2" style="">
                                    <a class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View</a>
                                    <a class="dropdown-item" href="#"><i class="ri-delete-bin-6-fill mr-2"></i>Delete</a>
                                    <a class="dropdown-item" href="#"><i class="ri-file-download-fill mr-2"></i>Download</a>
                                 </div>
                              </div>
                           </div> -->
                        </div>
                     </li>
                </ul>
            </div>
        </div>
    </div>
     <!-- <footer class="iq-footer">
      <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
               <div class="player row">
                 <div class="container-audio" v-for="play in getPlayer" :key="play.id">
                    <audio controls  loop autoplay controlsList="nodownload noplaybackrate">
                   <source :src="play.source">
               </audio>
               </div>
               </div>
            </div>
         </div>
      </div>
   </footer> -->
    <footer class="iq-footer">
      <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
               <div class="player row" v-for="play in getPlayer" :key="play.id">
                  <div class="details col-6 col-sm-4 col-md-4 col-lg-4">
                     <div class="now-playing"></div>
                     <div class="track-art"></div>
                     <div>
                        <div class="track-name">{{ play.title_ar }}</div>
                        <div class="track-artist">{{ play.name_ar }}</div>
                     </div>
                  </div>
                  <div class="slider_container slider_music col-sm-5 col-md-4 col-lg-4">
                     <div class="current-time">00:00</div>
                     <input type="range" min="1" max="100" value="0" class="seek_slider" onchange="seekTo()">
                     <div class="total-duration">00:00</div>
                  </div>
                  <div class="buttons col-6  col-sm-3 col-md-2  col-lg-2">
                     <div class="prev-track" onclick="prevTrack()"><i class="fa fa-step-backward fa-2x"></i></div>
                     <!-- <div class="playpause-track" onclick="playpauseTrack()"><i class="fa fa-play-circle fa-3x"></i></div> -->
                     <audio controls  loop autoplay controlsList="nodownload noplaybackrate">
                   <source :src="play.source">
               </audio>
                     <div class="next-track" onclick="nextTrack()"><i class="fa fa-step-forward fa-2x"></i></div>
                  </div>
                  <div class="slider_container sound col-sm-6 col-md-2  col-lg-2">
                     <i class="fa fa-volume-down"></i>
                     <input type="range" min="1" max="100" value="99" class="volume_slider" onchange="setVolume()">
                     <i class="fa fa-volume-up"></i>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next';
export default {
   props: {
      id: String,
    },
    async setup(props) {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie()
      const getPlayer = ref([]);
      const getCat = ref([]);
        try {
            await HTTP.get(`readPost.php?id=`+props.id).then((res) => {
                getPlayer.value = res.data.readPost;
            });
        } catch (err) {
            console.log(err);
        }
        try {
            await HTTP.get(`CatogeryById.php?LIMIT=20&Cat_id=`+cookie.getCookie("cat_id")).then((res) => {
                getCat.value = res.data.readPost;
            });
        } catch (err) {
            console.log(err);
        }
        const getFeed = (cat) => {
            router.push({ name: "MusicPlayer", params: { id: cat.id } });
        };
      return {
        getPlayer,
        getCat,
        getFeed
      }
    },
}
</script>

<style>
/* Start  styling the page */
.container-audio {
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 5px;
    /* background-color: #eee; */
    color: #444;
    margin: 20px auto;
    overflow: hidden;
}
audio {
  width:100%;
}
audio:nth-child(2), audio:nth-child(4), audio:nth-child(6) {
    margin: 0;
}
</style>